import { Injectable, HostListener } from "@angular/core";
import { BehaviorSubject, Observable, Subscriber } from "rxjs";
import { appConstant, UserType } from "src/app/service/appConstant";

// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService {
  public screenWidth: any;
  public collapseSidebar: boolean = false;
  public fullScreen = false;

  constructor() {
    this.onResize();
    if (this.screenWidth < 991) {
      this.collapseSidebar = true;
    }
  }

  // Windows width
  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  MENUITEMS: Menu[] = [
    {
      path: "/dashboard/default",
      title: "Dashboard",
      icon: "home",
      type: "link",
      //   badgeType: "primary",
      //   badgeValue: "new",
      active: true,
    },
    {
      title: "Constant",
      icon: "slack",
      type: "sub",
      active: false,
      children: [
        { path: "/constant/settings", title: "General-Settings", type: "link" },
        { path: "/constant/counters", title: "Counters", type: "link" },
        { path: "/constant/category", title: "Category", type: "link" },
        { path: "/constant/level", title: "Level", type: "link" },
        { path: "/constant/track", title: "Track", type: "link" },
        { path: "/constant/advantage", title: "Advantage", type: "link" },
        { path: "/constant/company", title: "Companies", type: "link" },
        { path: "/constant/social", title: "Social", type: "link" },
        { path: "/constant/static", title: "Static-Page", type: "link" },
        { path: "/constant/faq", title: "FAQ", type: "link" },
        { path: "/constant/complaints", title: "Contact-Us", type: "link" },
      ],
    },

    {
      title: "HR",
      icon: "activity",
      type: "sub",
      active: false,
      children: [
        {
          path: "/users/roles",
          title: "Roles",
          type: "link",
        },
        {
          path: "/users/admins",
          title: "Employee",
          type: "link",
        },
      ],
    },
    {
      title: "Users",
      icon: "users",
      type: "sub",
      active: false,
      children: [
        { path: "/users/clients", title: "Users", type: "link" },
        { path: "/users/teacher", title: "Teachers", type: "link" },
      ],
    },
    {
      title: "Courses",
      icon: "slack",
      type: "sub",
      active: false,
      children: [
        { path: "/course/endex", title: "EnOfCourseTests", type: "link" },
        { path: "/course/list", title: "Courses", type: "link" },
        { path: "/course/lesson", title: "Lessons", type: "link" },
        { path: "/course/exam", title: "Exams", type: "link" },
        { path: "/course/question", title: "Questions", type: "link" },
       
        { path: "/course/users", title: "CourseUsers", type: "link" },
        { path: "/course/discuss", title: "Discusion", type: "link" },
        { path: "/course/lesson/comment", title: "OrderComment", type: "link" },
        { path: "/course/tests", title: "FinalTests", type: "link" },
      ],
    },
    {
      title: "Offers",
      icon: "percent",
      type: "sub",
      active: false,
      children: [
        { path: "/offers/coupon", title: "Offers", type: "link" },
      ],
    },
    {
      title: "Notifications",
      icon: "bell",
      type: "sub",
      active: false,
      children: [
        { path: "/mass/add", title: "Notifications", type: "link" },
      ],
    },
      //     let obj7 = {
  //       path: "/mass/notifications",
  //       title: "التنبيهات",
  //       type: "link",
  //     };
  //     notification.children.push(obj7);

    // {
    //   title: "Subscribe",
    //   icon: "percent",
    //   type: "sub",
    //   active: false,
    //   children: [
    //     { path: "/users/subscribe", title: "Subscribe", type: "link" },
    //   ],
    // },
    // {
    //   title: "Orders",
    //   icon: "dollar-sign",
    //   type: "sub",
    //   active: false,
    //   children: [
    //     { path: "/orders/orders", title: "Orders", type: "link" },
    //     // { path: "/orders/comments", title: "OrderComment", type: "link" },
    //   ],
    // },
    // {
    //   title: "الرسائل والاشعارات",
    //   icon: "message-square",
    //   type: "sub",
    //   active: false,
    //   children: [
    //     { path: "/mass/notifications", title: "التنبيهات", type: "link" },
    //     { path: "/mass/add", title: "اضافة تنبيه", type: "link" },
    //   ],
    // },
  ];

  // getMenu() {
  //   var userRoles: any[] = JSON.parse(localStorage.getItem("roles"));
  //   var userType = localStorage.getItem("type");
  //   var admin_id = localStorage.getItem("admin_id");

  //   this.MENUITEMS = [];

  //   let constant = {
  //     title: "الثوابت",
  //     icon: "slack",
  //     type: "sub",
  //     active: false,
  //     children: [],
  //   };

  //   let users = {
  //     title: "المستخدمين والعملاء",
  //     icon: "users",
  //     type: "sub",
  //     active: false,
  //     children: [],
  //   };

  //   let items = {
  //     title: "المنتجات",
  //     icon: "list",
  //     type: "sub",
  //     active: false,
  //     children: [],
  //   };

  //   let orders = {
  //     title: "الطلبات والعائدات",
  //     icon: "dollar-sign",
  //     type: "sub",
  //     active: false,
  //     children: [],
  //   };

  //   let notification = {
  //     title: "الرسائل والاشعارات",
  //     icon: "message-square",
  //     type: "sub",
  //     active: false,
  //     children: [],
  //   };

  //   let offers = {
  //     title: "العروض والتخفيضات",
  //     icon: "percent",
  //     type: "sub",
  //     active: false,
  //     children: [],
  //   };

  //   if (userType == UserType.ADMIN) {
  //     userRoles.sort((val1, val2) => {
  //       return Number(val1.sort) - Number(val2.sort);
  //     });

  //     userRoles.forEach((element) => {
  //       if (element.name == appConstant.ADMIN_URL_ID.DASHBOARD) {
  //         let obj = {
  //           path: "/dashboard/default",
  //           title: "الرئيسية",
  //           icon: "home",
  //           type: "link",
  //           //   badgeType: "primary",
  //           //   badgeValue: "new",
  //           active: true,
  //         };
  //         this.MENUITEMS.push(obj);
  //       }
  //       if (
  //         element.name == appConstant.ADMIN_URL_ID.SETTINGS ||
  //         element.name == appConstant.ADMIN_URL_ID.WELCOME ||
  //         element.name == appConstant.ADMIN_URL_ID.COMPLAINS ||
  //         element.name == appConstant.ADMIN_URL_ID.CONTACT ||
  //         element.name == appConstant.ADMIN_URL_ID.COUNTRY ||
  //         element.name == appConstant.ADMIN_URL_ID.CITY ||
  //         element.name == appConstant.ADMIN_URL_ID.PLACES ||
  //         element.name == appConstant.ADMIN_URL_ID.STATIC ||
  //         element.name == appConstant.ADMIN_URL_ID.STYLES ||
  //         element.name == appConstant.ADMIN_URL_ID.REASON
  //       ) {
  //         if (element.name == appConstant.ADMIN_URL_ID.SETTINGS) {
  //           let obj = {
  //             path: "/constant/settings",
  //             title: "الاعدادات العامة",
  //             type: "link",
  //           };
  //           constant.children.push(obj);
  //         }
  //         if (element.name == appConstant.ADMIN_URL_ID.REASON) {
  //           let obj = {
  //             path: "/constant/reasons",
  //             title: "أسباب الغاء الطلب",
  //             type: "link",
  //           };

  //           constant.children.push(obj);
  //         }
  //         if (element.name == appConstant.ADMIN_URL_ID.CONTACT) {
  //           let obj = {
  //             path: "/constant/social",
  //             title: "التواصل الاجتماعي",
  //             type: "link",
  //           };

  //           constant.children.push(obj);
  //         }
  //         if (element.name == appConstant.ADMIN_URL_ID.STATIC) {
  //           let obj = {
  //             path: "/constant/static",
  //             title: "الصحفات الثابتة",
  //             type: "link",
  //           };

  //           constant.children.push(obj);
  //         }
  //         if (element.name == appConstant.ADMIN_URL_ID.COMPLAINS) {
  //           let obj = {
  //             path: "/constant/complaints",
  //             title: "التواصل والشكاوي",
  //             type: "link",
  //           };
  //           constant.children.push(obj);
  //         }
  //         if (element.name == appConstant.ADMIN_URL_ID.WELCOME) {
  //           let obj = {
  //             path: "/constant/welcome",
  //             title: "الواجهات الترحيبية",
  //             type: "link",
  //           };
  //           constant.children.push(obj);
  //         }
  //         if (element.name == appConstant.ADMIN_URL_ID.PLACES) {
  //           let obj = {
  //             path: "/constant/place",
  //             title: "أماكن التصاميم",
  //             type: "link",
  //           };
  //           constant.children.push(obj);
  //         }
  //         if (element.name == appConstant.ADMIN_URL_ID.STYLES) {
  //           let obj = {
  //             path: "/constant/style",
  //             title: "أنماط التصاميم",
  //             type: "link",
  //           };
  //           constant.children.push(obj);
  //         }
  //         if (element.name == appConstant.ADMIN_URL_ID.COUNTRY) {
  //           let obj = {
  //             path: "/constant/country",
  //             title: "قائمة الدول",
  //             type: "link",
  //           };
  //           constant.children.push(obj);
  //         }
  //         if (element.name == appConstant.ADMIN_URL_ID.CITY) {
  //           let obj = {
  //             path: "/constant/city",
  //             title: "قائمة المدن",
  //             type: "link",
  //           };
  //           constant.children.push(obj);
  //         }
  //       }

  //       if (
  //         element.name == appConstant.ADMIN_URL_ID.USER ||
  //         element.name == appConstant.ADMIN_URL_ID.STORES ||
  //         element.name == appConstant.ADMIN_URL_ID.ADMINS
  //       ) {
  //         if (element.name == appConstant.ADMIN_URL_ID.ADMINS) {
  //           let obj = {
  //             path: "/users/admins",
  //             title: "الادارة",
  //             type: "link",
  //           };
  //           users.children.push(obj);
  //         }

  //         if (element.name == appConstant.ADMIN_URL_ID.USER) {
  //           let obj = {
  //             path: "/users/clients",
  //             title: "العملاء",
  //             type: "link",
  //           };
  //           users.children.push(obj);
  //         }
  //         if (element.name == appConstant.ADMIN_URL_ID.STORES) {
  //           let obj = {
  //             path: "/users/designers",
  //             title: "المصممين والمتاجر",
  //             type: "link",
  //           };
  //           users.children.push(obj);
  //         }
  //       }

  //       if (
  //         element.name == appConstant.ADMIN_URL_ID.PRODUCTS ||
  //         element.name == appConstant.ADMIN_URL_ID.PROJECTS
  //       ) {
  //         if (element.name == appConstant.ADMIN_URL_ID.PRODUCTS) {
  //           let obj = {
  //             path: "/items/products",
  //             title: "المنتجات",
  //             type: "link",
  //           };
  //           items.children.push(obj);
  //         }

  //         if (element.name == appConstant.ADMIN_URL_ID.PROJECTS) {
  //           let obj = {
  //             path: "/items/projects",
  //             title: "المشاريع",
  //             type: "link",
  //           };
  //           items.children.push(obj);
  //         }
  //       }

  //       if (
  //         element.name == appConstant.ADMIN_URL_ID.ORDERS ||
  //         element.name == appConstant.ADMIN_URL_ID.ORDEREARNING ||
  //         element.name == appConstant.ADMIN_URL_ID.USERRATE
  //       ) {
  //         if (element.name == appConstant.ADMIN_URL_ID.ORDERS) {
  //           let obj = {
  //             path: "/orders/orders",
  //             title: "الطلبات",
  //             type: "link",
  //           };
  //           orders.children.push(obj);
  //         }

  //         if (element.name == appConstant.ADMIN_URL_ID.ORDEREARNING) {
  //           let obj = {
  //             path: "/orders/earning",
  //             title: "العائدات المالية",
  //             type: "link",
  //           };
  //           orders.children.push(obj);
  //         }

  //         if (element.name == appConstant.ADMIN_URL_ID.USERRATE) {
  //           let obj = {
  //             path: "/orders/comments",
  //             title: "التعليقات",
  //             type: "link",
  //           };
  //           orders.children.push(obj);
  //         }
  //       }

  //       if (
  //         element.name == appConstant.ADMIN_URL_ID.COUPON ||
  //         element.name == appConstant.ADMIN_URL_ID.COUPON
  //       ) {
  //         if (element.name == appConstant.ADMIN_URL_ID.COUPON) {
  //           let obj = {
  //             path: "/offers/coupon",
  //             title: "الكوبونات",
  //             type: "link",
  //           };

  //           offers.children.push(obj);
  //         }
  //       }

  //       if (
  //         element.name == appConstant.ADMIN_URL_ID.NOTIFICATIONS_ADD ||
  //         element.name == appConstant.ADMIN_URL_ID.NOTIFICATIONS ||
  //         element.name == appConstant.ADMIN_URL_ID.MYMESSAGES
  //       ) {
  //         if (element.name == appConstant.ADMIN_URL_ID.NOTIFICATIONS_ADD) {
  //           let obj = {
  //             path: "/mass/add",
  //             title: "اضافة تنبيه",
  //             type: "link",
  //           };

  //           notification.children.push(obj);
  //         }

  //         if (element.name == appConstant.ADMIN_URL_ID.NOTIFICATIONS) {
  //           let obj = {
  //             path: "/mass/notifications",
  //             title: "التنبيهات",
  //             type: "link",
  //           };
  //           notification.children.push(obj);
  //         }

  //         if (element.name == appConstant.ADMIN_URL_ID.MYMESSAGES) {
  //           let obj = {
  //             path: "/mass/messages",
  //             title: "الرسائل الواردة",
  //             type: "link",
  //           };
  //           notification.children.push(obj);
  //         }
  //       }
  //     });

  //     if (constant.children.length > 0) this.MENUITEMS.push(constant);
  //     if (users.children.length > 0) this.MENUITEMS.push(users);
  //     if (items.children.length > 0) this.MENUITEMS.push(items);
  //     if (orders.children.length > 0) this.MENUITEMS.push(orders);
  //     if (offers.children.length > 0) this.MENUITEMS.push(offers);
  //     if (notification.children.length > 0) this.MENUITEMS.push(notification);
  //     return this.MENUITEMS;
  //   }
  //   if (
  //     userType == UserType.DESIGNER ||
  //     userType == UserType.IMPLEMENTER ||
  //     userType == UserType.STORE
  //   ) {
  //     let obj = {
  //       path: "/dashboard/default",
  //       title: "الرئيسية",
  //       icon: "home",
  //       type: "link",
  //       active: true,
  //     };
  //     this.MENUITEMS.push(obj);

  //     let obj2 = {
  //       path: "/users/designers/details/" + admin_id,
  //       title: "الملف الشخصي",
  //       icon: "user",
  //       type: "link",
  //       active: true,
  //     };
  //     this.MENUITEMS.push(obj2);

  //     if (userType == UserType.DESIGNER || userType == UserType.IMPLEMENTER) {
  //       let obj3 = {
  //         path: "/items/projects",
  //         title: "المشاريع",
  //         icon: "slack",
  //         type: "link",
  //         active: true,
  //       };
  //       this.MENUITEMS.push(obj3);
  //     }

  //     if (userType == UserType.STORE) {
  //       let obj3 = {
  //         path: "/items/products",
  //         title: "المنتجات",
  //         icon: "slack",
  //         type: "link",
  //         active: true,
  //       };
  //       this.MENUITEMS.push(obj3);
  //     }
  //     let obj4 = {
  //       path: "/orders/orders",
  //       title: "الطلبات",
  //       type: "link",
  //     };
  //     orders.children.push(obj4);

  //     let obj5 = {
  //       path: "/orders/earning",
  //       title: "العائدات المالية",
  //       type: "link",
  //     };
  //     orders.children.push(obj5);

  //     let obj6 = {
  //       path: "/orders/comments",
  //       title: "التعليقات",
  //       type: "link",
  //     };
  //     orders.children.push(obj6);

  //     let obj7 = {
  //       path: "/mass/notifications",
  //       title: "التنبيهات",
  //       type: "link",
  //     };
  //     notification.children.push(obj7);

  //     let obj8 = {
  //       path: "/mass/messages",
  //       title: "الرسائل الواردة",
  //       type: "link",
  //     };
  //     notification.children.push(obj8);

  //     if (userType == UserType.IMPLEMENTER || userType == UserType.DESIGNER) {
  //       let obj9 = {
  //         path: "/chat",
  //         title: "المحادثات الفورية",
  //         type: "link",
  //       };
  //       notification.children.push(obj9);
  //     }

  //     if (orders.children.length > 0) this.MENUITEMS.push(orders);
  //     if (notification.children.length > 0) this.MENUITEMS.push(notification);

  //     return this.MENUITEMS;
  //   }
  // }
  // Array
  // items = new BehaviorSubject<Menu[]>(this.getMenu());
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
