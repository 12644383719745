import { Component, Input, OnInit } from "@angular/core";
import { appConstant, user_type } from "src/app/service/appConstant";
import { ConstantServiceWrapper } from "../../../service/ConstantServiceWrapper.service";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalConfig,
} from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-user-notification',
  templateUrl: './user-notification.component.html',
  styleUrls: ['./user-notification.component.scss']
})
export class UserNotificationComponent implements OnInit {
  @Input() userDetails: any;
  NOT_MSG_TXT= ""
  NOT_TITLe_TXT=""
  constructor(
    private helper: ConstantServiceWrapper,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService) { }

  ngOnInit(): void {
  }


  sendNotification() {
    this.helper
      .addSingleNotifications(this.userDetails._id, {
        title: this.NOT_TITLe_TXT,
        msg: this.NOT_MSG_TXT,
        type: this.userDetails.type,
      })
      .subscribe(
        (x) => {
          if (x[appConstant.STATUS]) {
            this.NOT_MSG_TXT= ""
            this.NOT_TITLe_TXT=""
            this.toastr.success(x[appConstant.MESSAGE]);
            this.modalService.dismissAll();
          } else {
            this.toastr.error(x[appConstant.MESSAGE]);
          }
        },
        (error) => {
          this.helper.serverSideErrorHandler(error);
        }
      );
  }

}
