import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { appConstant, ORDER_STATUS, PAYKENT_TYPE } from "../../../../app/service/appConstant";
import { ConstantServiceWrapper } from "../../../../app/service/ConstantServiceWrapper.service";

@Component({
  selector: "app-order-details",
  templateUrl: "./order-details.component.html",
  styleUrls: ["./order-details.component.scss"],
})
export class OrderDetailsPoPComponent implements OnInit {
  @Input() id: any;
  status_history = []
  orderDetails;
  PAYKENT_TYPE = PAYKENT_TYPE;
  ORDER_STATUS = ORDER_STATUS;  
  markers = [];
	lat = 0.0;
	lng = 0.0
  isLoading = false;
  constructor(  
    private route: ActivatedRoute, 
    private router: Router,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public helper: ConstantServiceWrapper
    ) {

    }

  ngOnInit(): void {
    this.getOrderDetails()
  }

  getOrderDetails(){
    this.isLoading = true
    this.helper.getOrderDetails(this.id).subscribe(x=>{
      this.isLoading = false
      this.orderDetails = x[appConstant.ITEMS] as any
      this.status_history = this.orderDetails['history'] as any[]

      let user_marker = {
        lat: this.orderDetails.lat,
        lng: this.orderDetails.lng,
        animation: "DROP",
        nameData: "Customer: " + this.orderDetails.user_id.full_name ? this.orderDetails.user_id.full_name : ""
      };
      this.lat = this.orderDetails.lat;
      this.lng = this.orderDetails.lng;
      this.markers.push(user_marker);
    })
  }

  openDialog(dialog) {
    // if(dialog == 'munjid') {
    //   let modalRef = this.modalService.open(OrderMunjidComponent, { size: "lg" });
    //   modalRef.componentInstance.orderDetails = this.orderDetails;
    // }
    // if(dialog == 'reason') {
    //   let modalRef = this.modalService.open(OrderReasonsComponent, { size: "lg" });
    //   modalRef.componentInstance.orderDetails = this.orderDetails;
    // }
  }
}
