import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/firebase/auth.service";

@Injectable({
  providedIn: "root",
})
export class AdminGuard implements CanActivate {
  constructor(public authService: AuthService, public router: Router) {}
  roleMatch(allowedRoles): boolean {
    var isMatch = false;
    var userRoles: any[] = JSON.parse(localStorage.getItem("roles"));
    var arr = [];
    // debugger;
    userRoles.forEach((element) => {
      allowedRoles.forEach(el => {
        if(element.path.includes(el)){
          arr.push(el)
        }
      });
      // arr.push(element.name);
    });
    if(arr.length > 0){
      return true
    }else{
      return false
    }
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // Guard for user is login or not
    // let user = JSON.parse(localStorage.getItem("user"));
    // if (!user) {
    //   this.router.navigate(["/auth/login"]);
    //   return true;
    // }
    // return true;

    if (localStorage.getItem("admin_id") != null) {
      let roles = next.data["roles"] as Array<String>;
      let expire = localStorage.getItem("expire");
      if (expire && new Date(expire) >= new Date()) {
        if (roles) {
          var match = this.roleMatch(roles);
          if (match) {
            return true;
          } else {
            this.router.navigate(["/error/403"]);
            return false;
          }
        } else {
          return true;
        }
      } else {
        this.router.navigate(["/auth/login"]);
        return false;
      }
    } else {
      this.router.navigate(["/auth/login"]);
      return false;
    }
  }
}
