import { Component, Input, OnInit } from "@angular/core";
import { appConstant } from "src/app/service/appConstant";
import { ConstantServiceWrapper } from "../../../service/ConstantServiceWrapper.service";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalConfig,
} from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";


@Component({
  selector: 'app-user-sms',
  templateUrl: './user-sms.component.html',
  styleUrls: ['./user-sms.component.scss']
})
export class UserSMSComponent implements OnInit {
  @Input() userDetails: any;
  SMS_TXT = "";

  constructor(
    private helper: ConstantServiceWrapper,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService) { }

  ngOnInit(): void {
  }


  sendSMS() {
    this.helper.sendUserSMS(this.userDetails._id, { msg: this.SMS_TXT, type: this.userDetails.type }).subscribe(
      (x) => {
        if (x[appConstant.STATUS]) {
          this.SMS_TXT = ""
          this.toastr.success(x[appConstant.MESSAGE]);
        } else {
          this.toastr.error(x[appConstant.MESSAGE]);
        }
        this.modalService.dismissAll();
      },
      (error) => {
        this.modalService.dismissAll();
        this.helper.serverSideErrorHandler(error);
      }
    );
  }
}
