import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CustomizerService } from "./shared/services/customizer.service";
import Pusher from 'pusher-js';
import { environment } from "../environments/environment";
import { ToastrService } from "ngx-toastr";
import { OrderDetailsPoPComponent } from "./shared/components/order-details/order-details.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
declare var require;
const Swal = require("sweetalert2");

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "endless";
  private pusherClient: Pusher;

  constructor(
    private translate: TranslateService,
    public customize: CustomizerService,
    public toaster : ToastrService,
    private modalService: NgbModal,
  ) {
    this.pusherClient = new Pusher(environment.Pusher_App_Key, { cluster: environment.Pusher_Channel });
    const channel = this.pusherClient.subscribe('orders');
    channel.bind(
      'posts',(data: { title: string; body: string; order_id: string }) => {
        this.playAudio();
        this.toaster.warning(data.body,data.title).onTap.subscribe(x=>{
          this.openOrder(data.order_id)
        })
      }
    );

  }

  playAudio() {
		let audio = new Audio();
		audio.src = "../assets/audio/1.mp3";
		audio.load();
		audio.play();
	}

  openOrder(order_id){
      let modalRef = this.modalService.open(OrderDetailsPoPComponent, { size: "xl" });
      modalRef.componentInstance.id = order_id
  }

  ngOnInit(): void {
    if (localStorage.getItem("lang")) {
      let lang = localStorage.getItem("lang");
      let dir = lang == "ar" ? "rtl" : "ltr";
      this.translate.use(lang);
      this.customize.setLayoutType(dir);
      this.customize.setLayout(dir);
    } else {
      localStorage.setItem("lang", "ar");
      this.translate.use("ar");
      this.customize.setLayoutType("rtl");
      this.customize.setLayout("rtl");
    }
  }
}
