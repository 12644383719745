import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-image-title-loader",
  templateUrl: "./image-title-loader.component.html",
  styleUrls: ["./image-title-loader.component.scss"],
})
export class ImageTitleLoaderComponent implements OnInit {
  @Input() count: number;
  @Input() showImage: boolean;
  @Input() showTitle: boolean;
  constructor() {}

  ngOnInit(): void {}

  createRange(number) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }
}
