import { Component, Input, ViewEncapsulation } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { UserType } from "src/app/service/appConstant";
import { ConstantServiceWrapper } from "src/app/service/ConstantServiceWrapper.service";
import { NavService, Menu } from "../../services/nav.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent {
  public menuItems: Menu[] = [];
  public url: any;
  public fileurl: any;
  public name = "";
  public user_id = "";
  public type = "";
  public userImage = null;
  public get UserType(): typeof UserType {
    return UserType;
  }

  constructor(
    private router: Router,
    public navServices: NavService,
    private helper: ConstantServiceWrapper
  ) {
    if (localStorage.getItem("token") != null) {
      this.name = localStorage.getItem("admin_username");
      this.type = localStorage.getItem("type");
      this.userImage = localStorage.getItem("image");
      this.user_id = this.helper.getDecryptionValue("admin_id");
    }
    this.navServices.items.subscribe((menuItems) => {
      let roles = JSON.parse(localStorage.getItem("roles"));
      menuItems.forEach(element => {
        if(element.title == "Dashboard"){
          this.menuItems.push(element);
        }else{
          var icon = ""
          if(element.title == "Constant"){
            icon = "slack"
          }
          if(element.title == "HR"){
            icon = "activity"
          }
          if(element.title == "Users"){
            icon = "users"
          }
          if(element.title == "Course"){
            icon = "percent"
          }
          if(element.title == "Offers"){
            icon = "percent"
          }
          if(element.title == "Notification"){
            icon = "bell"
          }
          let Constant : Menu = {
            title: element.title,
            icon: icon,
            type: "sub",
            active: false,
            children:[]
          }    
          roles.forEach(el => {
            element.children.forEach(_element => {
              // debugger;
              if(el.path == _element.path){
                if(!Constant.children.find(x=>x.path == el.path)) Constant.children.push(_element)
              }
            });
          });         
          if(Constant.children.length>0) this.menuItems.push(Constant);
        }
      });

      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.menuItems.filter((items) => {
            if (items.path === event.url) this.setNavActive(items);
            if (!items.children) return false;
            items.children.filter((subItems) => {
              if (subItems.path === event.url) this.setNavActive(subItems);
              if (!subItems.children) return false;
              subItems.children.filter((subSubItems) => {
                if (subSubItems.path === event.url)
                  this.setNavActive(subSubItems);
              });
            });
          });
        }
      });
    });
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter((menuItem) => {
      if (menuItem != item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true;
      if (menuItem.children) {
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach((a) => {
        if (this.menuItems.includes(item)) a.active = false;
        if (!a.children) return false;
        a.children.forEach((b) => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0) return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    };
  }
}
