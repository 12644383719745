// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCFyoXU_fZLj327BNEqBmjRqKOu5y2kzOg",
    authDomain: "doubleq-3e894.firebaseapp.com",
    projectId: "doubleq-3e894",
    storageBucket: "doubleq-3e894.appspot.com",
    messagingSenderId: "584942278312",
    appId: "1:584942278312:web:03371e9f0361257d83949d",
    measurementId: "G-YME7D1X8SH",
  },
  BASE_URL: "https://englishmastery.herokuapp.com/api",
  // BASE_URL: "http://0.0.0.0:3000/api",
  Pusher_App_Key:"0c5fadf8ec15b5898e1c",
  Pusher_Channel:"ap1",
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
