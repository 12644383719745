import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { appConstant } from "./appConstant";
import { ToastrService } from "ngx-toastr";
import * as CryptoJS from "crypto-js";
import { Observable, Subject, merge, of } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { TranslateService } from "@ngx-translate/core";
declare var require;
const Swal = require("sweetalert2");


const WIKI_URL = "https://en.wikipedia.org/w/api.php";
const PARAMS = new HttpParams({
  fromObject: {
    action: "opensearch",
    format: "json",
    origin: "*",
  },
});

@Injectable({
  providedIn: "root",
})
export class ConstantServiceWrapper {
  constructor(private http: HttpClient, private toastr: ToastrService , private translate: TranslateService) {}

  public change: EventEmitter<any> = new EventEmitter();

  public setLoading(value) {
    this.change.emit(value);
  }

  getMyToken() {
    if (localStorage.getItem("token")) {
      let decryptToken = CryptoJS.AES.decrypt(
        localStorage.getItem("token"),
        "DQ@KEY"
      );
      var decryptedData = decryptToken.toString(CryptoJS.enc.Utf8);
      return decryptedData;
    }
  }

  getUserType() {
    if (localStorage.getItem("type")) {
      return localStorage.getItem("type");
    }else{
      return ""
    }
  }

  getMyLang() {
    if (localStorage.getItem("lang")) return localStorage.getItem("lang");
  }

  getDecryptionValue(item) {
    let decryptVal = CryptoJS.AES.decrypt(localStorage.getItem(item), "DQ@KEY");
    var decryptedData = decryptVal.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  }

  serverSideErrorHandler(error) {
    if (error) {
      this.setLoading(false);
      this.toastr.error(error.error.message);
    }
  }

  customeErrorHandler(error) {
    if (error) {
      this.toastr.error(error.error.message);
    }
  }

  deleteAlert():Promise<any> {
    let title = this.translate.instant("Warning");
    let text =  this.translate.instant("Confirm");
    let yes = this.translate.instant("Yes");
    let no = this.translate.instant("Cancel");
    return new Promise (function(resolve, reject) { 
        resolve(Swal.fire({
          title: title,
          text:text,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: yes,
          cancelButtonText: no,
          // animation: false,
          // customClass: "animated tada",
        }))
    })
  }

  async checkAdmin() {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };

    return this.http.post(
      environment.BASE_URL + `/check`,
      JSON.stringify({ token: this.getMyToken() }),
      httpOptions
    );
  }

  loginAdmin(conent) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/admin/login",
      JSON.stringify(conent),
      httpOptions
    );
  }

  //constant
  updateSettings(id, conent) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "multipart/form-data",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/settings/" + id,
      conent,
      httpOptions
    );
  }

  getSittings() {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + "/constant/settings",
      httpOptions
    );
  }

  
  getCounters() {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + "/constant/counters" ,
      httpOptions
    );
  }


  getSingleSittings() {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + "/constant/settings",
      httpOptions
    );
  }

  addStatic(content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
        token: this.getMyToken(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/static-pages",
      JSON.stringify(content),
      httpOptions
    );
  }

  updateStatic(id, conent) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
        token: this.getMyToken(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/static/" + id,
      JSON.stringify(conent),
      httpOptions
    );
  }

  getStatic() {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + "/constant/static-pages",
      httpOptions
    );
  }

  getSingleStatic(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + "/constant/static/" + id,
      httpOptions
    );
  }

  updateContact(id, conent) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),

        token: this.getMyToken(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/contact/" + id,
      JSON.stringify(conent),
      httpOptions
    );
  }

  getContact() {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + "/constant/contact",
      httpOptions
    );
  }

  getSingleContact(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + "/constant/contact/" + id,
      httpOptions
    );
  }

  getWelcome() {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + "/constant/welcome",
      httpOptions
    );
  }

  getSingleWelcome(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + "/constant/welcome/" + id,
      httpOptions
    );
  }

  addWelcome(content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "multipart/form-data",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
        token: this.getMyToken(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/welcome",
      content,
      httpOptions
    );
  }

  updateWelcome(id, conent) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "multipart/form-data",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
        token: this.getMyToken(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/welcome/" + id,
      conent,
      httpOptions
    );
  }

  deleteWelcome(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/delete-welcome/" + id,
      {},
      httpOptions
    );
  }

  getAllType() {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(environment.BASE_URL + "/constant/types", httpOptions);
  }

  getType() {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(environment.BASE_URL + "/constant/types", httpOptions);
  }

  getCompany() {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(environment.BASE_URL + "/constant/company", httpOptions);
  }

  getAdvantages() {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(environment.BASE_URL + "/constant/advantage", httpOptions);
  }


  getSingleType(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + "/constant/single-types/" + id,
      httpOptions
    );
  }

  getSingleCompany(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + "/constant/single-company/" + id,
      httpOptions
    );
  }

  getSingleAdvantage(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + "/constant/single-advantage/" + id,
      httpOptions
    );
  }



  addType(content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "multipart/form-data",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/types",
      content,
      httpOptions
    );
  }

  addAdvantages(content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
            }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/advantage",
      JSON.stringify(content),
      httpOptions
    );
  }

  addCompanies(content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "multipart/form-data",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/company",
      content,
      httpOptions
    );
  }

  updateType(id, conent) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "multipart/form-data",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/types/" + id,
      conent,
      httpOptions
    );
  }

  updateCompany(id, conent) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "multipart/form-data",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/company/" + id,
      conent,
      httpOptions
    );
  }


  updateAdvantage(id, conent) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/advantages/" + id,
      JSON.stringify(conent),
      httpOptions
    );
  }


  deleteType(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/delete-types/" + id,
      {},
      httpOptions
    );
  }

  deleteCompany(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/delete-company/" + id,
      {},
      httpOptions
    );
  }


  deleteAdvantages(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang()
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/delete-advantage/" + id,
      {},
      httpOptions
    );
  }

  getFAQ(page, limit) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL +
        "/constant/faq" +
        "?page=" +
        page +
        "&limit=" +
        limit,
      httpOptions
    );
  }
  
  getCategory(page, limit) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL +
        "/constant/category" +
        "?page=" +
        page +
        "&limit=" +
        limit,
      httpOptions
    );
  }

  getTrack(page, limit) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL +
        "/constant/track" +
        "?page=" +
        page +
        "&limit=" +
        limit,
      httpOptions
    );
  }

  getLevel(page, limit) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL +
        "/constant/level" +
        "?page=" +
        page +
        "&limit=" +
        limit,
      httpOptions
    );
  }


  getPackage(page, limit) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL +
        "/constant/package" +
        "?page=" +
        page +
        "&limit=" +
        limit,
      httpOptions
    );
  }

  getSingleFAQ(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + "/constant/single-faq/" + id,
      httpOptions
    );
  }

  getSingleCategory(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + "/constant/single-category/" + id,
      httpOptions
    );
  }

  getSingleLevel(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + "/constant/single-level/" + id,
      httpOptions
    );
  }

  getSingleTrack(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + "/constant/single-track/" + id,
      httpOptions
    );
  }
  
  getSinglePackage(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + "/constant/single-package/" + id,
      httpOptions
    );
  }

  addFAQ(content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/faq",
      content,
      httpOptions
    );
  }

  addCategory(content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/category",
      content,
      httpOptions
    );
  }
  addCounters(id,content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/counters/"+id,
      content,
      httpOptions
    );
  }


  addTrack(content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/track",
      content,
      httpOptions
    );
  }

  addLevel(content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/level",
      content,
      httpOptions
    );
  }

  addPackage(content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/package",
      content,
      httpOptions
    );
  }

  updateFAQ(id, conent) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/faq/" + id,
      conent,
      httpOptions
    );
  }

  updateCategory(id, conent) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/category/" + id,
      conent,
      httpOptions
    );
  }

  updateTrack(id, conent) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/track/" + id,
      conent,
      httpOptions
    );
  }

  updateLevel(id, conent) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/level/" + id,
      conent,
      httpOptions
    );
  }

  updatePackage(id, conent) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/package/" + id,
      conent,
      httpOptions
    );
  }

  deleteFAQ(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/delete-faq/" + id,
      {},
      httpOptions
    );
  }

  deleteCategory(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/delete-category/" + id,
      {},
      httpOptions
    );
  }

  deleteTrack(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/delete-track/" + id,
      {},
      httpOptions
    );
  }

  deletelevel(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/delete-level/" + id,
      {},
      httpOptions
    );
  }

  deletePackage(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/delete-package/" + id,
      {},
      httpOptions
    );
  }

  activePackage(id,body) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/active-package/" + id,
      JSON.stringify(body),
      httpOptions
    );
  }

  getComplaints(page, limit) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL +
        "/constant/complaints?page=" +
        page +
        "&limit=" +
        limit,
      httpOptions
    );
  }

  replyComplaints(content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/reply-complaints",
      JSON.stringify(content),
      httpOptions
    );
  }

  deleteComplaints(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/constant/complaints/" + id,
      {},
      httpOptions
    );
  }


  //discuss
  getDiscuss(page, limit,course) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL +
        "/answer/get?page=" + page + "&limit=" + limit + "&course=" + course ,
      httpOptions
    );
  }

  replyDiscuess(id,content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/answer/add/"+id,
      JSON.stringify(content),
      httpOptions
    );
  }

  deleteDiscuss(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/answer/delete/" + id,
      {},
      httpOptions
    );
  }

  deleteComment(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/mobile/lesson/comment/delete/" + id,
      {},
      httpOptions
    );
  }



  //comments
  getLessonComments(page, limit, lesson) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
        "token": this.getMyToken()
      }),
    };
    return this.http.get(
      environment.BASE_URL +
        "/mobile/lesson/comment?page=" + page + "&limit=" + limit + "&lesson_id=" + lesson ,
      httpOptions
    );
  }

  replyLessonComment(id,content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/course/lesson/comment/"+id,
      JSON.stringify(content),
      httpOptions
    );
  }

  //my students
  getMyStudents(course) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
        token: this.getMyToken(),
      }),
    };
    return this.http.get(
      environment.BASE_URL +
        "/courses/trainer?course=" + course,
      httpOptions
    );
  }

  // users
  getUsers(page, limit, content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL +
        `/users/clients?search_field=${content.search_field}&search_value=${content.search_value}&page=${page}&limit=${limit}`,
      httpOptions
    );
  }
  getSingleUser(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + `/users/profile/` + id,
      httpOptions
    );
  }
  getUsersExcel(content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL +
        `/users/clients-excel?search_field=${content.search_field}&search_value=${content.search_value}`,
      httpOptions
    );
  }  
  deleteUser(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/users/delete/"+id,
      {},
      httpOptions
    );
  }

  blockUnblockUser(content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/users/block",
      JSON.stringify(content),
      httpOptions
    );
  }
  sendUserSMS(id, content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/users/sms/" + id,
      JSON.stringify(content),
      httpOptions
    );
  }
  sendUserNotification(id, content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/users/notification/" + id,
      JSON.stringify(content),
      httpOptions
    );
  }
  updateUser(conent) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "multipart/form-data",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/users/update",
      conent,
      httpOptions
    );
  }
  getUserCourse(id, page, limit) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + `/users/course/${id}?page=${page}&limit=${limit}`,
      httpOptions
    );
  }
  getUserExam(id, page, limit) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + `/users/exam/${id}?page=${page}&limit=${limit}`,
      httpOptions
    );
  }
  getUserLevel(id, page, limit) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + `/users/level/${id}?page=${page}&limit=${limit}`,
      httpOptions
    );
  }
  //teacher
  getAllTeacher() {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL +
        `/teacher/all`,
      httpOptions
    );
  }
  getTeacher(page, limit, content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL +
        `/teacher/clients?search_field=${content.search_field}&search_value=${content.search_value}&page=${page}&limit=${limit}`,
      httpOptions
    );
  }
  deleteTeacher(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/teacher/delete/"+id,
      {},
      httpOptions
    );
  }
  getSingleTeacher(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + `/teacher/profile/` + id,
      httpOptions
    );
  }
  getTeacherExcel(content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL +
        `/teacher/clients-excel?search_field=${content.search_field}&search_value=${content.search_value}`,
      httpOptions
    );
  } 
  updateTeacher(conent) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "multipart/form-data",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/teacher/update",
      conent,
      httpOptions
    );
  }
  addTeacher(conent) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "multipart/form-data",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/teacher/add",
      conent,
      httpOptions
    );
  }
  //orders
  getMapOrders(content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + `/orders/map?statusId=${content.statusId}&order_no=${content.order_no}&employee_id=${content.employee_id}&paymentType=${content.paymentType}&start_date=${content.dt_from}&end_date=${content.dt_to}`,
      httpOptions
    );
  }

  getOrders(page, limit, content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + `/orders/search?page=${page}&limit=${limit}&statusId=${content.statusId}&order_no=${content.order_no}&employee_id=${content.employee_id}&paymentType=${content.paymentType}&start_date=${content.dt_from}&end_date=${content.dt_to}`,
      httpOptions
    );
  }
  
  getOrderDetails(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + `/orders/details/${id}`,
      httpOptions
    );
  }

  deleteRate(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/users/rates/" + id,
      {},
      httpOptions
    );
  }
  
  getOrdersRate(page, limit, content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    console.log(content.dt_from)
    return this.http.get(
      environment.BASE_URL + `/orders/rates?page=${page}&limit=${limit}&order_no=${content.order_no}&employee_id=${content.employee_id}&start_date=${content.dt_from}&end_date=${content.dt_to}`,
      httpOptions
    );
  }

  updateOrderStatus(id, content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + `/orders/update?order_id=${id}`,
      JSON.stringify(content),
      httpOptions
    );
  }

  //nottifications
  getTop10Notification() {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + "/admin/top_notification",
      httpOptions
    );
  }

  getNotifications(page, limit, content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + `/admin/notification?page=${page}&limit=${limit}`,
      JSON.stringify(content),
      httpOptions
    );
  }
  
  addNotifications(content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/admin/add_notification/"+content._id,
      content,
      httpOptions
    );
  }
  
  addSingleNotifications(id, content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/admin/add_notification/" + id,
      content,
      httpOptions
    );
  }

  //admins
  getAdmins(page, limit) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + "/admin/list?page=" + page + "&limit=" + limit,
      httpOptions
    );
  }
  getSingleAdmins(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + "/admin/details/" + id,
      httpOptions
    );
  }
  addAdmin(content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "multipart/form-data",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/admin/add",
      content,
      httpOptions
    );
  }
  updateAdmin(id, conent) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "multipart/form-data",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/admin/update/" + id,
      conent,
      httpOptions
    );
  }
  deleteAdmin(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/admin/delete/" + id,
      {},
      httpOptions
    );
  }
  updateMyProfile(id, conent) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "multipart/form-data",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "accept-language": this.getMyLang(),
        token: this.getMyToken(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/admin/my/" + id,
      conent,
      httpOptions
    );
  }

  //roles
  getAllPermissions() {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(environment.BASE_URL + "/pages/all", httpOptions);
  }
  getAllRoles() {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(environment.BASE_URL + "/roles/all", httpOptions);
  }
  getRoles(page, limit) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + "/roles/list?page=" + page + "&limit=" + limit,
      httpOptions
    );
  }
  getSingleRoles(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + "/roles/get/" + id,
      httpOptions
    );
  }
  addRoles(content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/roles/add",
      content,
      httpOptions
    );
  }
  updateRole(id, conent) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/roles/update/" + id,
      conent,
      httpOptions
    );
  }
  deleteRole(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/roles/delete/" + id,
      {},
      httpOptions
    );
  }


  //charts
  getTop10NewUsers() {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
      }),
    };
    return this.http.get(environment.BASE_URL + "/report/new-user",httpOptions
    );
  }

  getTop10Orders() {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
      }),
    };
      return this.http.get(environment.BASE_URL + "/report/new-order",httpOptions);
  }

  getCounterOrdersWithStatus() {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
      }),
    };
    return this.http.get(environment.BASE_URL + "/report/orders",httpOptions);
  }

  getCounterUsers() {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
      }),
    };
    return this.http.get(environment.BASE_URL + "/report/users",httpOptions);
  }

  UsersproviderPerYear() {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
      }),
    };
    return this.http.get(environment.BASE_URL + "/report/user-year",httpOptions);

  }
  
  
  getTopCourse() {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + "/report/top-course",
      httpOptions
    );
  }

  getTopUsers() {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + "/report/top-users",
      httpOptions
    );
  }

  getTopProductsCategory() {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + "/report/top-product-category",
      httpOptions
    );
  }
  
  //coupon
  getCoupon(page, limit, content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL +
        `/coupon/list?page=${page}&limit=${limit}&coupon=${content.coupon}&isActive=${content.isActive}&orderField=${content.orderField}&orderDirection=${content.orderDirection}`,
      httpOptions
    );
  }
  getSingleCoupon(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + "/coupon/details/" + id,
      httpOptions
    );
  }
  addCoupon(content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/coupon/add",
      content,
      httpOptions
    );
  }
  updateCoupon(id, conent) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/coupon/update/" + id,
      conent,
      httpOptions
    );
  }
  deleteCoupon(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/coupon/delete/" + id,
      {},
      httpOptions
    );
  }


  //messages
  getMyMessages(page, limit) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.get(
      environment.BASE_URL + "/message/list?page=" + page + "&limit=" + limit,
      httpOptions
    );
  }

  replyMessage(content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/message/add",
      JSON.stringify(content),
      httpOptions
    );
  }

  deleteMessage(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/message/delete/" + id,
      {},
      httpOptions
    );
  }
  hideShow(content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/items/activate",
      JSON.stringify(content),
      httpOptions
    );
  }
  hideShowSlider(content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/advs/activate",
      JSON.stringify(content),
      httpOptions
    );
  }

  markImageAsDefault(id, content) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getMyToken(),
        "accept-language": this.getMyLang(),
      }),
    };
    return this.http.post(
      environment.BASE_URL + "/products/mark_image_default/" + id,
      JSON.stringify(content),
      httpOptions
    );
  }

    //subsctibe
    getSubscribeList(page, limit, search) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.get(
        environment.BASE_URL + `/subscribe/list?page=${page}&limit=${limit}&full_name=${search.full_name}&dt_from=${search.dt_from}&dt_to=${search.dt_to}&package=${search.package}`,
        httpOptions
      );
    }

    
    getSubscribeExcelList(search) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.get(
        environment.BASE_URL + `/subscribe/list?full_name=${search.full_name}&dt_from=${search.dt_from}&dt_to=${search.dt_to}&package=${search.package}`,
        httpOptions
      );
    }
    deleteSubscribe(id,body) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.post(
        environment.BASE_URL + "/subscribe/active/" + id,
        JSON.stringify(body),
        httpOptions
      );
    }


    //course
    getTrainerCourse() {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          "accept-language": this.getMyLang(),
          token: this.getMyToken(),
        }),
      };
      return this.http.get(
        environment.BASE_URL +"/courses/fortrainer",
        httpOptions
      );
    }

    getAllCourses() {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          "accept-language": this.getMyLang(),
          token: this.getMyToken(),
        }),
      };
      return this.http.get(
        environment.BASE_URL +"/courses/all",
        httpOptions
      );
    }

    getCourse(page, limit,search) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.get(
        environment.BASE_URL + "/course/search?page=" + page + "&limit=" + limit+"&track="+search.track+"&level="+search.level+"&category="+search.category+"&dt_from="+search.dt_from+"&dt_to="+search.dt_to+"&full_name="+search.full_name,
        httpOptions
      );
    }

    addCourse(conent) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "multipart/form-data",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.post(
        environment.BASE_URL + "/course/add",
        conent,
        httpOptions
      );
    }

    getSingleCourse(id) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.get(
        environment.BASE_URL + "/course/details/" + id,
        httpOptions
      );
    }

    editCourse(id,conent) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "multipart/form-data",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.post(
        environment.BASE_URL + "/course/edit/"+id,
        conent,
        httpOptions
      );
    }

    addStudentCertificate(body) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.post(
        environment.BASE_URL + "/course/certificate",
        JSON.stringify(body),
        httpOptions
      );
    }

    massageSms(body) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.post(
        environment.BASE_URL + "/course/massage-sms",
        JSON.stringify(body),
        httpOptions
      );
    }
    addStudentCourse(body) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.post(
        environment.BASE_URL + "/course/add-course",
        JSON.stringify(body),
        httpOptions
      );
    }
    deleteStudentCourse(id) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.post(
        environment.BASE_URL + "/course/delete-course/" + id,
        {},
        httpOptions
      );
    }

    deleteCourse(id) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.post(
        environment.BASE_URL + "/course/delete/" + id,
        {},
        httpOptions
      );
    }

    //lesson
    getAllLesson() {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          "accept-language": this.getMyLang(),
          token: this.getMyToken(),
        }),
      };
      return this.http.get(
        environment.BASE_URL +"/lesson/all",
        httpOptions
      );
    }

    getLesson(page, limit,search) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.get(
        environment.BASE_URL + "/lesson/search?page=" + page + "&limit=" + limit+"&course="+search.course+"&full_name="+search.full_name,
        httpOptions
      );
    }

    getLessonByCourse(courseId){
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.get(
        environment.BASE_URL + "/lesson/course-lessons?course="+courseId,
        httpOptions
      );
    }

    addLesson(conent) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "multipart/form-data",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.post(
        environment.BASE_URL + "/lesson/add",
        conent,
        httpOptions
      );
    }

    editLesson(id,conent) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "multipart/form-data",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.post(
        environment.BASE_URL + "/lesson/add/"+id,
        conent,
        httpOptions
      );
    }

    getSingleLesson(id) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.get(
        environment.BASE_URL + "/lesson/details/" + id,
        httpOptions
      );
    }

    deleteLesson(id) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.post(
        environment.BASE_URL + "/lesson/delete/" + id,
        {},
        httpOptions
      );
    }
    //lesson
    // getAllTests() {
    //   const httpOptions = {
    //     headers: new HttpHeaders({
    //       Content: "application/json",
    //       Accept: "application/json",
    //       "Content-Type": "application/json; charset=utf-8",
    //       "Access-Control-Allow-Origin": "*",
    //       "accept-language": this.getMyLang(),
    //       token: this.getMyToken(),
    //     }),
    //   };
    //   return this.http.get(
    //     environment.BASE_URL +"/exam/list",
    //     httpOptions
    //   );
    // }

    getAllTests(page, limit) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.get(
        environment.BASE_URL + "/exam/list?page=" + page + "&limit=" + limit+"&type=Final",
        httpOptions
      );
    }

    getTestByCourse(courseId){
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.get(
        environment.BASE_URL + "/lesson/course-lessons?course="+courseId,
        httpOptions
      );
    }

    addTest(conent) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "multipart/form-data",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.post(
        environment.BASE_URL + "/lesson/add",
        conent,
        httpOptions
      );
    }

    editTest(id,conent) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "multipart/form-data",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.post(
        environment.BASE_URL + "/lesson/add/"+id,
        conent,
        httpOptions
      );
    }

    getSingleFinalTest(id) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.get(
        environment.BASE_URL + "/exam/view/" + id,
        httpOptions
      );
    }

    // deleteTest(id) {
    //   const httpOptions = {
    //     headers: new HttpHeaders({
    //       Content: "application/json",
    //       Accept: "application/json",
    //       "Content-Type": "application/json; charset=utf-8",
    //       "Access-Control-Allow-Origin": "*",
    //       token: this.getMyToken(),
    //       "accept-language": this.getMyLang(),
    //     }),
    //   };
    //   return this.http.post(
    //     environment.BASE_URL + "/lesson/delete/" + id,
    //     {},
    //     httpOptions
    //   );
    // }
    //exam
    getAllExam() {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.get(
        environment.BASE_URL + "/exam/all",
        httpOptions
      );
    }

    getExam(page, limit,search) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.get(
        environment.BASE_URL + "/exam/search?page=" + page + "&limit=" + limit+"&course="+search.course+"&full_name="+search.full_name+"&lesson="+search.lesson,
        httpOptions
      );
    }

    addExam(conent) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.post(
        environment.BASE_URL + "/exam/add",
        conent,
        httpOptions
      );
    }

    editExam(id,conent) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.post(
        environment.BASE_URL + "/exam/add/"+id,
        conent,
        httpOptions
      );
    }

    getSingleExam(id) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.get(
        environment.BASE_URL + "/exam/details/" + id,
        httpOptions
      );
    }

    deleteExam(id) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.post(
        environment.BASE_URL + "/exam/delete/" + id,
        {},
        httpOptions
      );
    }

    //question
    getQuestion(page, limit,search) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.get(
        environment.BASE_URL + "/question/search?page=" + page + "&limit=" + limit+"&exam="+search.exam+"&full_name="+search.full_name,
        httpOptions
      );
    }

    addQuestion(conent) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.post(
        environment.BASE_URL + "/question/add",
        conent,
        httpOptions
      );
    }

    editQuestion(id,conent) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.post(
        environment.BASE_URL + "/question/add/"+id,
        conent,
        httpOptions
      );
    }

    getSingleQuestion(id) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.get(
        environment.BASE_URL + "/question/details/" + id,
        httpOptions
      );
    }

    deleteQuestion(id) {
      const httpOptions = {
        headers: new HttpHeaders({
          Content: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          token: this.getMyToken(),
          "accept-language": this.getMyLang(),
        }),
      };
      return this.http.post(
        environment.BASE_URL + "/question/delete/" + id,
        {},
        httpOptions
      );
    }
}

export interface Product {
  _id: string;
  arName: string;
  enName: string;
  image: string;
}
