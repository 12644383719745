import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { appConstant } from "src/app/service/appConstant";
import { ConstantServiceWrapper } from "src/app/service/ConstantServiceWrapper.service";

@Component({
  selector: "app-drop-down-type",
  templateUrl: "./drop-down-type.component.html",
  styleUrls: ["./drop-down-type.component.scss"],
})
export class DropDownTypeComponent implements OnInit {
  @Input() selected: Object;
  @Output() valueChanged = new EventEmitter<any>();

  types = [];
  lang = "";
  constructor(
    private helper: ConstantServiceWrapper,
    private traslate: TranslateService
  ) {
    this.lang = this.traslate.currentLang;
  }

  ngOnInit(): void {
    this.getAllType();
  }

  getAllType() {
    this.helper.getAllType().subscribe((x) => {
      this.types = x[appConstant.ITEMS] as any[];
    });
  }
}
